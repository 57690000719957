import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from "../assets/img/nav-icon4.svg";

const linkedIn = "https://www.linkedin.com/in/nadinebernardino/";
const telegram = "https://t.me/popsolmemes";

const Footer = () => {
  return (
    <footer className="footer">
      <h1>Let's challenge the established!</h1>
      <div className="socialsHeader" style={{ paddingBottom: "100px" }}>
        <div className="social-icon">
          <a href={linkedIn}>
            <img src={navIcon3} alt="" />
          </a>
          <a href="">
            <img src={navIcon4} alt="" />
          </a>
        </div>
      </div>
      <div style={{ margin: "20px", textAlign: "center" }}>
        2025 NadineBernardino. © All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
